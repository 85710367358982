@tailwind base;
@tailwind components;
@tailwind utilities;

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

.customSpeakerView{
  border: solid 1px red;
}
.customParticipantView{

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #5a8bff;
}

a:hover {
  opacity: 0.8;
}


@media only screen and (max-width: 800px) {
  .container {
    padding: 8px;
  }

  .entrySection {
    grid-template-columns: 1fr;
    margin-top: 20px;
    gap: 20px;
  }

  .prejoin main {
    width: 100%;
  }
}

.entrySection .label {
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 10px;
}

.entrySection input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 16px;
  background-color: transparent;
  color: white;
  padding: 8px;
}

.entrySection input[type='text'] {
  width: calc(100% - 16px);
}

.entrySection div {
  vertical-align: middle;
}

.videoSection {
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-top: 20px;
}

.videoSection video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.videoSection {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #2f2f2f;
}



.controlSection {
  margin-top: 30px;
  display: grid;
  grid-template-columns: auto auto minmax(100px, min-content);
}

.controlSection .right {
  grid-column: 3 / 4;
}


.iconButton {
  cursor: pointer;
}

.iconButton:disabled {
  cursor: auto;
}

.participantCount {
  font-size: 14px;
}

.participantCount span {
  display: inline-block;
  margin-left: 5px;
}

